import styled from "styled-components";

export const Titulo = styled.div`
    width: 100%;
    overflow: hidden;
    min-height: 31.25rem;
    h1 {
        width: 100%;
        padding-top: 8.781rem;
        font-family: "ubuntu";
        font-weight: bold;
        color: var(--secondary-text);
        font-size: clamp(1.063rem, 2.5vw, 2.5rem);
        line-height: 46px;
        text-align: center;
        position: absolute;
        text-transform: uppercase;
    }
    picture > img {
        overflow: hidden;
        position: absolute;
        object-fit: cover;
        width: 100%;
        min-height: 38.438rem;
        margin-top: clamp(4.269rem, 5vw, 6.269rem );
        z-index: -1000;
    }
    @media (max-width: 48em) {
        min-height: 16.688rem;
        h1 {
            padding-top: 4.781rem;
        }
        picture > img {
            overflow: hidden;
            position: absolute;
            object-fit: cover;
            width: 100%;
            min-height: 12.688rem;
            margin-top: 4rem;
            z-index: -1000;
        }
    }
`;

export const Beneficios = styled.div`
    background-color: var(--primary);
    .boxes {
        display: flex;
        justify-content: center;
        position: relative;
        top: -11.875rem;
        .box {
            width: 360px;
            height: 360px;
            text-align: center;
            padding-top: 80px;
            h1 {
                font-family: "Ubuntu";
                font-weight: bold;
                color: var(--secondary-text);
                font-size: clamp(1.063rem, 2.5vw, 2.5rem);
                line-height: 46px;
                margin-top: 20px;
            }
        }

        .saude {
            background-color: var(--beneficios-box-1);
            :hover .off-saude {
                display: initial;
            }
            :hover .on-saude {
                display: none;
            }
            .off-saude {
                display: none;
                p {
                    font-family: "Ubuntu";
                    font-weight: bold;
                    color: var(--secondary-text);
                    font-size: 20px;
                    line-height: 24px;
                    text-align: initial;
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }

        .educacao {
            background-color: var(--beneficios-box-2);
            :hover .off-educacao {
                display: block;
            }
            :hover .on-educacao {
                display: none;
            }
            .off-educacao {
                display: none;
                p {
                    font-family: "Ubuntu";
                    font-weight: bold;
                    color: var(--secondary-text);
                    font-size: 20px;
                    line-height: 24px;
                    text-align: initial;
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
        .lazer {
            background-color: var(--beneficios-box-3);
            :hover .off-lazer {
                display: block;
            }
            :hover .on-lazer {
                display: none;
            }
            .off-lazer {
                display: none;
                p {
                    font-family: "Ubuntu";
                    font-weight: bold;
                    color: var(--secondary-text);
                    font-size: 20px;
                    line-height: 24px;
                    text-align: initial;
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
    @media (max-width: 48rem) {
        .boxes {
            top: -6.75rem;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 0;
                width: 6.25rem;
                height: 6.25rem;
                img {
                    width: 51%;
                }
                h1 {
                    margin-top: 0.5rem;
                    font-size: 0.563rem;
                    line-height: 0.625rem;
                }
                .on-educacao {
                    img {
                        width: 41%;
                    }
                }
                .off-educacao {
                    display: block;
                    background-color: var(--beneficios-box-2);
                }
                .on-lazer {
                    img {
                        width: 81%;
                    }
                }
            }
            div[class*="off-"] {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                text-align: left;
                position: absolute;
                width: 18.75rem;
                height: 7.75rem;
                top: 6.26rem;
                left: calc(50% - 9.375rem);
                p {
                    margin: 0 auto !important;
                    margin-top: 1rem !important;
                    font-size: 0.875rem !important;
                    line-height: 1rem !important;
                }
            }
            .lazer {
                .off-lazer {
                    display: block;
                    background-color: var(--beneficios-box-3);
                    /* right: 12.5rem; */
                }
                :hover .on-lazer {
                    display: block;
                }
            }
            .educacao {
                .off-educacao {
                    display: block;
                    /* right: 6.25rem; */
                }
                :hover .on-educacao {
                    display: block;
                }
            }
            .saude {
                .off-saude {
                    display: block;
                    background-color: var(--beneficios-box-1);
                }
                :hover .on-saude {
                    display: block;
                }
            }
        }
    }
`;

export const Descontos = styled.div`
    background-color: var(--primary);
    h1 {
        font-family: "ubuntu";
        font-weight: bold;
        color: var(--secondary-text);
        font-size: 40px;
        line-height: 46px;
        text-align: center;
        text-transform: uppercase;
        padding: 40px 0;        
        @media (max-width: 48rem) {
            font-size: 1.063rem;
            line-height: 1.188rem;
        }
    }
    .especiais {
        background-color: var(--beneficios-info-mobile);
        display: grid;
        padding: 0 45px;
        height: 360px;
        grid-template-columns: 240px auto;
        grid-gap: 56px;
        align-items: center;
        max-width: 1020px;
        margin: 0 auto;
        margin-top: 20px;

        img {
            max-width: 100%;
            width: 200px;
            max-height: 76px;
        }
        h4 {
            font-family: "ubuntu";
            font-weight: bold;
            color: var(--accent);
            font-size: 40px;
            line-height: 46px;
            text-transform: uppercase;
        }
        p {
            font-family: "ubuntu";
            font-weight: bold;
            color: var(--secondary-text);
            font-size: 30px;
            line-height: 35px;
        }
        @media (max-width: 768px) {
            grid-template-columns: 85px auto;
            grid-gap: 16px;
            height: auto;
            padding: 20px 12px;
            h4 {
                font-size: 16px;
                line-height: 21px;
            }
            p {
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
    .especiais.escudo {
        img {
            width: 130px;
            margin-inline: auto;
            max-height: 160px;
            @media (max-width: 768px) {
                max-height: 3rem;
            }
        }
    }
    @media (max-width: 768px) {
        .especiais {
            img {
                min-height: 3rem;
            }
        }
    }
    // Footer
    & + div {
        padding-top: 25rem;
        @media (max-width: 80em) {
            padding-top: 5rem;
            margin-top: 0;
        }
    }
`;
