import styled from "styled-components";

export const NextMatchContainer = styled.div`
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.563rem;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  bottom: -22px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  overflow-wrap: break-word;
  padding: 0px;
  position: relative;
  text-align: center;
  margin: -5.913rem auto;

  height: 5.625rem;
  width: 15.625rem;
  z-index: 999;

  & span {
    height: 60%;
    align-self: center;
    color: rgba(112, 112, 112, 1);
    font-size: clamp(0.625rem, 1.5vw, 1.5rem);
  }
`;

export const Players = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

export const NextMatchPlayer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 14vw;
  & img {
    box-sizing: content-box;
    height: 2.3rem;
    width: auto;
  }
  & img.home {
    display: block;
    height: 2.3rem;
    width: auto;
    z-index: 40;
  }
  & span {
    color: var(--next-match-txt);
    flex-wrap: nowrap;
    font-family: "Ubuntu", sans-serif;
    font-size: 0.625rem;
    line-height: 0.688rem;
    font-weight: 700;
    margin-top: 0.5rem;
    overflow: visible;
    white-space: nowrap;
  }
`;

export const NextMatchTitle = styled.p`
  color: var(--next-match-txt);
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  font-family: "Ubuntu", sans-serif;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
  span {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 0.688rem;
    text-align: center;
  }
  span:nth-child(n+2):nth-child(-n+2) {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 0.688rem;
    text-align: center;
  }
  @media (max-width: 60em) {
    margin-top: 0;
  }
`;

export const NextMatchDate = styled.p`
  color: rgba(112, 112, 112, 1);
  font-family: Helvetica, sans-serif;
  font-size: clamp(0.5rem, 1.5vw, 1.5rem);
  font-weight: 500;
  margin: auto 0;
`;
