import axios from "axios";
import { NEXT_MATCH_API, RAPID_API_HOST, RAPID_API_KEY, TEAM_ID } from "../context/CampaignContext/static";

export class AxiosRequestNextGame {
  async get (): Promise<any> {
    const params = {
      team: TEAM_ID,
      season: 2023,
      next: 1
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "X-RapidAPI-Key": RAPID_API_KEY,
      "X-RapidAPI-Host": RAPID_API_HOST
    }
    const response = await axios(NEXT_MATCH_API, { params, headers });
    let output = {
      date: response.data.response[0].fixture?.date,
      league: response.data.response[0].league,
    }
    if (response.data.response[0].teams.home.id === params.team) {
      return { ...output, ...response.data.response[0].teams.away };
    }
    return {  ...output, ...response.data.response[0].teams.home, };
  }
}