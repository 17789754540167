import styled from 'styled-components'

export const Button = styled.button`
  background-color: var(--btn-secondary-bg);
  border: none;
  font-family: 'ubuntu';
  font-weight: bold;
  font-size: 20px;
  color: var(--btn-secondary-txt);
  margin: 0 auto;
  border-radius: 30px;

  &:hover {
    color: var(--btn-primary-txt);
    background-color: var(--btn-primary-bg);
  }
`
