import { Col, Container, Row } from 'react-bootstrap';

import * as S from './style';
import face from '../../assets/svgs/facebook.svg'
import insta from '../../assets/svgs/instagram.svg'
import tw from '../../assets/svgs/twitter.svg'
import g from '../../assets/svgs/gt7.svg'
import { DefaultButton } from '../DefaultButton';
import { useCampaign } from '../../context/CampaignContext';

export default function Footer(){
    const { campaignData } = useCampaign();
    return<>
        <S.Footer>
            <Container>
                <Row>
                    <Col md='3'>
                        <img src={campaignData.teamShield} alt="logo time" />
                    </Col>
                    <Col md='3'>
                        <p>CENTRAL DE VENDAS</p>
                        <a href="tel:08007292078">0800 729 2078</a>
                    </Col>
                    <Col md='3'>
                        <p>SAC</p>
                        <a href="tel:08002838916">0800 283 8916</a>
                    </Col>
                    <Col md='3'>
                        <div className='social'>
                            <a href={campaignData.socials.facebook} target='blank'>
                                <img src={face} alt="logo facebook" />
                            </a>
                            <a href={campaignData.socials.instagram} target='blank'>
                                <img src={insta} alt="logo instagram" />
                            </a>
                            <a href={campaignData.socials.twitter} target='blank'>
                                <img src={tw} alt="logo twitter" />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='txt-footer'>
                        <p>* Cartão de desconto não é plano de saúde, não garante e não se responsabiliza pelos serviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será por ele pago ao prestador, assegurando-se apenas os preços e descontos que constam na relação de empresas e serviços conveniados disponibilizada no site www.cartaodetodos.com.br. O atendimento será em até 15 dias, mediante disponibilidade na clínica. Consulte a clínica parceira para as especialidades disponíveis e condições para marcar as consultas.</p>
                    </div>
                    <div className='credits'>
                        <div className="main-credits">
                            <p>Um produto</p>
                            <S.Button href={campaignData.socials.site}>
                                <DefaultButton>
                                    Cartão de TODOS ©
                                </DefaultButton>
                            </S.Button>
                            <S.Button href="https://cartaodetodos.com.br/wp-content/uploads/2023/04/Contrato-de-AdCDT2023.pdf">
                                <DefaultButton>
                                    Contrato de adesão
                                </DefaultButton>
                            </S.Button>
                        </div>
                        <a className="made-by" href="https://gt7.com.br">
                            <img src={g} alt="logo gt7" />
                        </a>
                    </div>
                </Row>
            </Container>
        </S.Footer>
    </>
}